<template>
  <div class="top">
    <div class="top__t">
      <content-title :first="'榜单导航'"></content-title>
      <div class="top__t_container">
        <div class="top__t_table" v-for="(item, index) in list" :key="index">
          <div class="top__t_table-header">{{ item.catName }}</div>
          <div class="top__t_table-text">
            <div
              class="table-text__content"
              v-for="(citem, cindex) in item.childCate"
              :key="cindex"
            >
              <span
                :class="{ isClick: citem.isClick }"
                @click="handleClick(citem)"
                >{{ citem.catName }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="top__c">
      <content-title :first="'排行榜'"></content-title>
      <!-- <flex-layout
        :list="topList"
        :row="4"
        :type="'timer'"
        :path="path"
      ></flex-layout> -->
      <release-layout
        :data="topList"
        :row="4"
        :id="'layout2'"
        :type="'top'"
        :to="path"
      ></release-layout>
      <div class="button">
        <button v-if="isMore" @click="more">加载更多</button>
      </div>
    </div>
  </div>
</template>
<script>
import contentTitle from "../components/title.vue";
import releaseLayout from "../components/releaseLayout.vue";
import top from "../api/top";
import lib from "../api/lib";
export default {
  data() {
    return {
      list: [],
      topList: [],
      pages: "1",
      id: "",
      path: {
        url: "/top/info",
        meta: "",
      },
      isMore:true
    };
  },
  components: {
    contentTitle,
    releaseLayout,
  },
  watch: {
    pages(val) {
      this.getFirm(true);
    },
    
  },
  created() {
    this.getCate();
    this.getFirm();
  },
  methods: {
    init() {
      for (let i = 0; i < this.list.length; i++) {
        let data = this.list[i].childCate;
        for (const c in data) {
          data[c].isClick = false;
        }
      }
    },
    getCate() {
      lib.getCate(1).then((res) => {
        this.list = res.data.data;
        this.init();
      });
    },
    getFirm(isMore) {
      let data = {
        page: this.pages,
        catId: this.id,
      };
      top.getFirm(data).then((res) => {
        let newList = [];
        let list = res.data.data.data;
        if(list.length == 0){
          this.isMore = false
        }
        list.forEach((item) => {
          let data = {
            id: item.rankId,
            url: item.rankIcon,
            newsTitle: item.paramKey,
            createTime: item.createTime.split(" ")[0],
            rankName:item.rankName,
            
          };
          newList.push(data);
        });
        this.topList = isMore? [...this.topList, ...newList] : newList;
      });
    },
    handleClick(item) {
      if(item.isClick){
        item.isClick = !item.isClick;
        
      }else{
        this.init();
        item.isClick = true;
      }
      
      console.log(item.isClick)
      this.path.meta = item.title;
      this.list.splice(this.list.length);
      this.id = item.isClick ? item.catId : "";
      this.pages = '1';
      this.getFirm();
      console.log(item);
    },
    more() {
      this.pages++;
    },
  },
};
</script>
<style lang="scss" scoped>
.top {
  .top__t {
    margin-top: 32px;
    .top__t_container {
      padding: 27px;
      background: #fff;
      border: 1px solid #e9e9e9;
    }
    .top__t_table {
      background: #fff;
      display: grid;
      grid-template-columns: 313px auto;
      grid-template-rows: 53px;
      .top__t_table-header,
      .top__t_table-text {
        width: 100%;
        display: flex;
        align-items: center;
        border: 1px solid #e9e9e9;
        margin-left: -1px;
        margin-bottom: -1px;
      }
      .top__t_table-header {
        justify-content: center;
        font-size: 19px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
      .table-text__content {
        margin: 0 15px;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        cursor: pointer;
        span {
          padding: 5px 10px;
        }
        .isClick {
          border-radius: 40px 40px 40px 40px;
          background: #0092ff;
          color: #fff;
        }
      }
    }
  }
  .top__c {
    margin-top: 40px;
    .button {
      text-align: center;
      margin-top: 65px;
      margin-bottom: 207px;
    }
    button {
      padding: 17px 64px;
      outline: none;
      background: #fff;
      border: 1px solid #0394ff;
      color: #0394ff;
      border-radius: 65px;
      font-size: 21px;
    }
  }
}
</style>